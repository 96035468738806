import React, { useState } from 'react'
import IconButton from '@mui/material/IconButton'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import { DialogButtonBack } from '../base/DialogButtons'
import { JsonIcon } from '../base/Icons'

export default function ButtonDialogEvJsonViewer(props) {
  const [showDialog, setShowDialog] = useState(false)
  const onClose = e => {
    setShowDialog(false)
  }
  return (
    <>
      <IconButton size='large' onClick={e => { setShowDialog(true) }}><JsonIcon /></IconButton>
      <Dialog open={showDialog} onClose={onClose}>
        <DialogContent>
          <pre>{JSON.stringify(props.ev, undefined, 2)}</pre>
        </DialogContent>
        <DialogActions>
          <DialogButtonBack onClick={onClose} />
        </DialogActions>
      </Dialog>
    </>
  )
}
