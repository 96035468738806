import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardActionArea from '@mui/material/CardActionArea'
import CardContent from '@mui/material/CardContent'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import ShareIcon from '@mui/icons-material/Share'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { enqueueSnackbar } from 'notistack'
import getDistance from 'geolib/es/getDistance'
import { evPageUrl } from '../../utils/routing'
import TagChip from '../tag/TagChip'
import { FavoritesIcon, TrashIcon } from '../base/Icons'
import { startMomentShortStr, endMomentShortStr } from '../../model/Ev'

function EvDate(props) {
  const { i18n } = useTranslation()
  const startDate = startMomentShortStr(props.ev, i18n.resolvedLanguage)
  const endDate = endMomentShortStr(props.ev, i18n.resolvedLanguage)
  return (
    <Box sx={{ display: 'inline-flex' }}>
      <Typography variant="body2">{startDate}</Typography>
      { endDate &&
      <>
        <ChevronRightIcon fontSize='small' sx={{ ml: 0.3, mr: 0.3 }} />
        <Typography variant="body2">{endDate}</Typography>
      </>
      }
    </Box>
  )
}

function EvAddress(props) {
  const distance = (props.refPlace == null) ? null : getDistance(
      { latitude: props.ev.location.latitude, longitude: props.ev.location.longitude },
      { latitude: props.refPlace.lat, longitude: props.refPlace.lon }, 100) / 1000
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
      <Box>
        <Typography variant="body2" textAlign='right'>{props.ev.location.name}</Typography>
        <Typography variant="body2" textAlign='right'>{props.ev.location.address.addressLocality}</Typography>
      </Box>
      {(distance != null) &&
      <>
        <Divider orientation='vertical' flexItem variant='fullWidth' />
        <Typography variant="h6" noWrap sx={{ overflow: 'unset' }}>{distance} km</Typography>
      </>
      }
    </Box>
  )
}

function EvTags(props) {
  return (
    <>
    {(props.ev.tags.length !== 0) &&
    <Stack direction='column' alignItems='flex-end' spacing='1px' sx={{ mt: 1 }}>
      {props.ev.tags && props.ev.tags.map((tag, idx) => (
        <TagChip key={idx} tag={tag} size='medium' edged='right' onClick={props.onTagClick} />
      ))}
    </Stack>
    }
    </>
  )
}

export default function EvTile(props) {
  const { t } = useTranslation()
  const onClick = e => {
    props?.onEvClick(props.ev)
  }
  const onShare = e => {
    if (navigator.share) {
      navigator.share({
        title: props.ev.name,
        text: "Evènement à partager : " + props.ev.name,
        url: evPageUrl(props.ev.id)
      })
    }
    else {
      enqueueSnackbar(t('feature-unavail-in-nav'), { variant: 'warning', preventDuplicate: true })
    }
  }
  return ( (props.ev?.isTrashed !== true) &&
    <Card elevation={0}>
      <CardActionArea onClick={onClick}>
        <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <EvDate ev={props.ev} />
          <Typography variant="h5" sx={{ fontFamily: 'BrandonText-Bold', whiteSpace: 'normal', mt: 2.5, mb: 3 }} textAlign='center' >{props.ev.name}</Typography>
          <EvAddress ev={props.ev} refPlace={props.refPlace}/>
        </CardContent>
      </CardActionArea>
      <EvTags ev={props.ev} onTagClick={props.onTagClick} />
      <CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
        <IconButton onClick={onShare}><ShareIcon color='tertiary_dark' /></IconButton>
        <IconButton onClick={e => {}}><FavoritesIcon color='tertiary_dark' /></IconButton>
        <IconButton onClick={e => {}}><TrashIcon color='tertiary_dark' /></IconButton>
      </CardActions>
    </Card>
  )
}
