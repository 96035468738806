import React, { useState } from 'react'
import { useQuery, gql } from "@apollo/client"
import Autocomplete from '@mui/material/Autocomplete'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import TagChip from '../tag/TagChip'
import { DialogButtonBack } from '../base/DialogButtons'

const LIST_TAGS = gql`
query listTags {
  listTags {
    id
    name
    bgColor
    color
  }
}`

export default function ButtonDialogTags(props) {
  const [showDialog, setShowDialog] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const { loading, error, data } = useQuery(LIST_TAGS)
  const tagOptions = (data == null) ? [] : data.listTags
  const onClose = e => {
    setShowDialog(false)
  }
  return (
    <>
      <Button variant='contained' size='large' color='secondary' onClick={e => { setShowDialog(true) }}>
        Tags
      </Button>
      <Dialog open={showDialog} onClose={onClose}>
        <DialogTitle>Tags</DialogTitle>
        <DialogContent>
        <Autocomplete
            size='small'
            renderInput={(props_) =>
              <TextField {...props_} color='secondary' sx={{ backgroundColor: '#fcfcfc' }} />
            }
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <TagChip tag={option} size='medium' {...getTagProps({ index })} />
              ))
            }
            openText=''
            closeText=''
            clearText=''
            noOptionsText='/'
            clearIcon={null}
            ListboxProps={{
              sx: { backgroundColor : '#f0f0f0' }
            }}
            options={tagOptions}
            getOptionLabel={(option) => option.name}
            getOptionDisabled={(option) => { 
              if (props.tagsOut) {
                for (let i=0; i<props.tagsOut.length; i++) {
                  if (props.tagsOut[i].name === option.name) {
                    return true
                  }
                }
              }
              return false
            }}
            multiple
            filterSelectedOptions
            value={props.tagsIn}
            onChange={(e,value,reason) =>
              props.onTagsInChange && props.onTagsInChange(value)
            }
          />
          <Autocomplete
            size='small'
            renderInput={(props_) =>
              <TextField {...props_} color='secondary' sx={{ backgroundColor: '#fcfcfc' }} />
            }
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <TagChip tag={option} size='medium' {...getTagProps({ index })} />
              ))
            }
            openText=''
            closeText=''
            clearText=''
            noOptionsText='/'
            clearIcon={null}
            ListboxProps={{
              sx: { backgroundColor : '#f0f0f0' }
            }}
            options={tagOptions}
            getOptionLabel={(option) => option.name}
            getOptionDisabled={(option) => { 
              if (props.tagsIn) {
                for (let i=0; i<props.tagsIn.length; i++) {
                  if (props.tagsIn[i].name === option.name) {
                    return true
                  }
                }
              }
              return false
            }}
            multiple
            filterSelectedOptions
            value={props.tagsOut}
            onChange={(e,value,reason) =>
              props.onTagsOutChange && props.onTagsOutChange(value)
            }
          />
        </DialogContent>
        <DialogActions>
          <DialogButtonBack onClick={onClose} />
        </DialogActions>
      </Dialog>
    </>
  )
}
