import { BrowserRouter, Routes, Route } from "react-router"
import { SnackbarProvider } from 'notistack'
import './App.css'
import './i18n/config.js'
import AppSsoProvider from './AppSsoProvider'
import AppApolloProvider from './AppApolloProvider'
import AppThemeProvider from './AppThemeProvider'
import LandingPage from "./ui/pages/LandingPage"
import ResultsPage from "./ui/pages/ResultsPage"
import EvPage from './ui/pages/EvPage'
import Page404 from "./ui/pages/Page404"
import AccountActivationPage from "./ui/pages/AccountActivationPage"

export default function App() {
  return (
    <AppSsoProvider>
      <SnackbarProvider anchorOrigin={{ vertical: 'top', horizontal: 'center' }} maxSnack={3} autoHideDuration={3000} />
      <AppApolloProvider>
        <AppThemeProvider>
          <BrowserRouter>
            <Routes>
              <Route path='/' element={<LandingPage />} />
              <Route path='events' element={<ResultsPage />} />
              <Route path='event'>
                <Route path=':evid' element={<EvPage />} />
              </Route>
              <Route path='action'>
                <Route path='activate-account' element={<AccountActivationPage />} />
              </Route>
              <Route path='*' element={<Page404 />} />
            </Routes>
          </BrowserRouter>
        </AppThemeProvider>
      </AppApolloProvider>
    </AppSsoProvider>
  )
}
