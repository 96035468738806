import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLazyQuery, gql } from "@apollo/client"
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import Skeleton from '@mui/material/Skeleton'
import ButtonDialogEvJsonViewer from "./ButtonDialogEvJsonViewer"
import TagStack from '../tag/TagStack'
import { evRecents } from '../../model/Recents'
import { startMomentShortStr, endMomentShortStr } from '../../model/Ev'
import { evTransform } from '../../model/Ev'

const GET_EVENTS_BY_ID = gql`
query getEventsById($ids: [String]!) {
  getEventsById(ids: $ids) {
    id
    name
    location
    starttime
    endtime
    coordinates
    tags {
      id
      name
      bgColor
      color
    }
    address
    allDayEvent
    creator
    createdat
    description
    eventlocation {
      coodinates
      description
    }
    externalId
    url
  }
}`

export default function EvCardMaxi(props) {
  const [ ev, setEv ] = useState(null)
  const [ err, setErr ] = useState(null)
  const { i18n } = useTranslation()
  // eslint-disable-next-line no-unused-vars
  const [getEventsById, { called, loading, data_ }] = useLazyQuery(GET_EVENTS_BY_ID)

  useEffect(() => {
    if (props.evid == null) {
      return
    }
    evRecents.push(props.evid)
    getEventsById({
      variables: {
        ids: [props.evid]
      },
      onCompleted: data => {
        if (data.getEventsById.length === 1) {
          setEv(evTransform(data.getEventsById[0]))
        }
        else {
          setErr('Evènement inconnu ou privé')
        }
      },
      onError: err => {
        // nop
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.evid])

  if (err) {
    return <Alert severity='error'>{err}</Alert>
  }

  if (ev == null) {
    return <Skeleton variant='rectangular' height='30px' />
  }

  const startDate = startMomentShortStr(ev, i18n.resolvedLanguage)
  const endDate = endMomentShortStr(ev, i18n.resolvedLanguage)

  return (
    <>
      <Typography variant="h5" noWrap>{ev.name}</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="body2" noWrap>{startDate}</Typography>
        { endDate &&
        <Typography variant="body2" noWrap>{endDate}</Typography>
        }
        { ev.location &&
        <Box>
          { ev.location.name &&
          <Typography variant="body2">{ev.location.name}</Typography>
          }
          { ev.location.address.streetAddress &&
          <Typography variant="body2">{ev.location.address.streetAddress}</Typography>
          }
          <Typography variant="body2">{ev.location.address.postalCode} {ev.location.address.addressLocality}</Typography>
        </Box>
        }
      </Box>
      <Box sx={{ mt: 1.5 }}>
        <TagStack tags={ev.tags} onClick={props.onTagClick}/>
      </Box>
      { ev.description &&
        <>
          <Divider sx={{ m: 0, mt: 1, mb: 1 }} />
          <Typography variant="body2">{ev.description}</Typography>
        </>
      }
      <Divider sx={{ m: 0, mt: 1, mb: 1 }} />
      <Box>
        <ButtonDialogEvJsonViewer ev={ev} />
      </Box>
    </>
  )
}

// <IconButton color='tertiary_dark' href={evPageUrl(ev.id)} >
// <Visibility />
// </IconButton>

//import iconShare from '../images/icons8-share-rounded-60.png'
//import iconFavorite from '../images/icons8-favorite-60.png'
//import iconHeart from '../images/icons8-heart-60.png'
//import iconTrash from '../images/icons8-trash-60.png'
// todo add doorbell for notifications.

//  { false &&
//    <CardActions disableSpacing>
//      { this.ev.location.latitude &&
//      <IconButton onClick={() => this.onIconMarkerClick(this.ev.id)}>
//        <img src={iconMarker} width="20" height="20" alt="?" />
//      </IconButton>
//      }
//      { navigator.share &&
//      <IconButton onClick={() => this.onIconShareClick(this.ev.id)}>
//        <img src={iconShare} width="20" height="20" alt="?" />
//      </IconButton>
//      }
//      {  this.ev.isFavorite &&
//      <IconButton onClick={() => this.onIconFavoriteClick(this.ev.id, false)}>
//        <img src={iconHeart} width="20" height="20" alt="?" />
//      </IconButton>
//      }
//      {  !this.ev.isFavorite &&
//      <IconButton onClick={() => this.onIconFavoriteClick(this.ev.id, true)}>
//        <img src={iconFavorite} width="20" height="20" alt="?" />
//      </IconButton>
//      }
//      <IconButton onClick={() => this.onIconTrashClick(this.ev.id)}>
//        <img src={iconTrash} width="20" height="20" alt="?" />
//      </IconButton>
//    </CardActions>
//  }

//onIconMarkerClick(evid) {
//    if (this.props.onIconMarkerClick) {
//      this.props.onIconMarkerClick(evid)
//    }
//  }
//
//  onIconFavoriteClick(evid, value) {
//    if (this.props.onIconFavoriteClick) {
//      this.props.onIconFavoriteClick(evid, value)
//    }
//  }
//
//  onIconTrashClick(evid) {
//    if (this.props.onIconTrashClick) {
//      this.props.onIconTrashClick(evid)
//    }
//  }
//
//  onIconShareClick(evid) {
//    if (navigator.share) {
//      const dat = {
//        title: this.ev.name,
//        text: "Evènement à partager : " + this.ev.name,
//        url: evPageUrl(evid)
//      }
//      navigator.share(dat)
//    }
//  }
//