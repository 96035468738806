import { useState } from 'react'
import Button from '@mui/material/Button'
import { enqueueSnackbar } from 'notistack'
import { getMyPosition } from '../../utils/geolocation'
import NearMeIcon from '@mui/icons-material/NearMe';

export default function ButtonGeolocation(props) {
  const [loading, setLoading] = useState(false)
  const onGeolocate = e => {
    e.preventDefault()
    e.stopPropagation()
    const onSuccess_ = place_ => {
      setLoading(false)
      props.onChange(place_)
    }
    const onError_ = err => {
      setLoading(false)
      enqueueSnackbar('cannot get your position', { variant: 'error' }) // todo
    }
    setLoading(true)
    getMyPosition(onSuccess_, onError_)
  }
  return (
    <Button variant="contained" size='large' color='secondary' onClick={onGeolocate} loading={loading}>
      <NearMeIcon fontSize='small' />
    </Button>
  )
}
