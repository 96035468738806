import { ThemeProvider, createTheme } from '@mui/material/styles'

export default function AppThemeProvider(props) {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#d79778",
        contrastText: '#fff',
      },
      secondary: {
        main: "#f6c270",
        contrastText: '#fff',
      },
      tertiary_light: { // white
        main: "#fff",
        contrastText: '#000',
      },
      tertiary_dark: { // black
        main: "#000",
        contrastText: '#fff',
      },
    },
    typography: {
      fontFamily: 'BrandonText-Regular',
      button: {
        textTransform: 'none'
      }
    },
    components: {
      MuiDialogActions: {
        styleOverrides: {
          root: {
            justifyContent: 'space-between'
          },
        },
      },
    },
  })
  return (
    <ThemeProvider theme={theme}>
      {props.children}
    </ThemeProvider>
  )  
}
