import { useTranslation } from 'react-i18next'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import NotInterestedIcon from '@mui/icons-material/NotInterested'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { DialogButtonBack } from '../base/DialogButtons'

export default function DialogTagChoice(props) {
  const { t } = useTranslation()

  if (props.tag == null) {
    return (<></>)
  }

  let isTagIn = false
  if (props.tagsIn) {
    for (let i = 0; i < props.tagsIn.length; i++) {
      if (props.tagsIn[i].id === props.tag.id) {
        isTagIn = true
        break
      }
    }
  }

  let isTagOut = false
  if (props.tagsOut) {
    for (let i = 0; i < props.tagsOut.length; i++) {
      if (props.tagsOut[i].id === props.tag.id) {
        isTagOut = true
        break
      }
    }
  }

  const setTagIn = () => {
    if (isTagIn && !isTagOut) {
      return
    }
    let tagsIn_  = (props.tagsIn) ? props.tagsIn : []
    let tagsOut_ = (props.tagsOut) ? props.tagsOut : []
    tagsIn_.push(props.tag)
    if (isTagOut) {
      tagsOut_ = tagsOut_.filter((tag_) => tag_.id !== props.tag.id)
    }
    props.onTagsChange(tagsIn_, tagsOut_)
  }

  const unsetTag = () => {
    if (!isTagIn && !isTagOut) {
      return
    }
    let tagsIn_  = (props.tagsIn) ? props.tagsIn : []
    let tagsOut_ = (props.tagsOut) ? props.tagsOut : []
    if (isTagIn) {
      tagsIn_ = tagsIn_.filter((tag_) => tag_.id !== props.tag.id)
    }
    if (isTagOut) {
      tagsOut_ = tagsOut_.filter((tag_) => tag_.id !== props.tag.id)
    }
    props.onTagsChange(tagsIn_, tagsOut_)
  }

  const setTagOut = () => {
    if (!isTagIn && isTagOut) {
      return
    }
    let tagsIn_  = (props.tagsIn) ? props.tagsIn : []
    let tagsOut_ = (props.tagsOut) ? props.tagsOut : []
    if (isTagIn) {
      tagsIn_ = tagsIn_.filter((tag_) => tag_.id !== props.tag.id)
    }
    tagsOut_.push(props.tag)
    props.onTagsChange(tagsIn_, tagsOut_)
  }

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>#{props?.tag?.name}</DialogTitle>
      <DialogContent>
        <List>
          <ListItemButton selected={isTagIn} onClick={e => setTagIn()} >
            <ListItemIcon><ThumbUpIcon color='tertiary_dark' /></ListItemIcon>
            <ListItemText primary={t('tag-set-in', {tagName: props?.tag?.name})} secondary={t('tag-set-in-help', {tagName: props?.tag?.name})} />
          </ListItemButton>
          <ListItemButton selected={(!isTagIn && !isTagOut)} onClick={e => unsetTag()} >
            <ListItemIcon><MoreHorizIcon color='tertiary_dark' /></ListItemIcon>
            <ListItemText primary={t('tag-unset', {tagName: props?.tag?.name})} secondary={t("tag-unset-help", {tagName: props?.tag?.name})} />
          </ListItemButton>
          <ListItemButton selected={isTagOut} onClick={e => setTagOut()} >
            <ListItemIcon><NotInterestedIcon color='tertiary_dark' /></ListItemIcon>
            <ListItemText primary={t('tag-set-out', {tagName: props?.tag?.name})} secondary={t('tag-set-out-help', {tagName: props?.tag?.name})} />
          </ListItemButton>
        </List>
      </DialogContent>
      <DialogActions>
        <DialogButtonBack onClick={props.onClose} />
      </DialogActions>
    </Dialog>
  )
}
