import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { DialogButtonBack } from '../base/DialogButtons'
import { SortByDetails } from '../../defs/SortBy'

export default function ButtonDialogSortBy(props) {
  const { t } = useTranslation()
  const [showDialog, setShowDialog] = useState(false)
  const onClose = e => {
    setShowDialog(false)
  }
  const onChange = value => {
    props.onChange(value)
    setTimeout(onClose, 200)
  }
  return (
    <>
      <Button variant='contained' size='large' color='secondary' onClick={e => { setShowDialog(true) }}>
        {t(SortByDetails[props.value].HELP)}
      </Button>
      <Dialog open={showDialog} onClose={onClose}>
        <DialogTitle>Trier les évènements</DialogTitle>
        <DialogContent>
          <List color='secondary'>
            {Object.keys(SortByDetails).map(key => (
            <ListItemButton key={key} color='secondary' selected={(props.value === key)} onClick={e => onChange(key)}>
              <ListItemIcon>
                {SortByDetails[key].ICON}
              </ListItemIcon>
              <ListItemText
                primary={t(SortByDetails[key].LABEL)}
                secondary={t(SortByDetails[key].HELP)}
              />
            </ListItemButton>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <DialogButtonBack onClick={onClose} />
        </DialogActions>
      </Dialog>
    </>
  )
}
