import { useParams } from 'react-router'
import Paper from '@mui/material/Paper'
import Layout from "../layout/Layout"
import EvCardMaxi from '../ev/EvCardMaxi'

export default function EvPage(props) {
  const { evid } = useParams()
  return (
    <Layout>
      { evid &&
      <Paper elevation={0} sx={{ mt: 1, p: 2 }}>
        <EvCardMaxi evid={evid}/>
      </Paper>
      }
    </Layout>
  )
}
