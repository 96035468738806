import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import EvCardMaxi from "./EvCardMaxi"
import { DialogButtonBack } from '../base/DialogButtons'

export function EvPopup(props) {
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogContent>
        <EvCardMaxi evid={props.ev?.id} />
      </DialogContent>
      <DialogActions>
        <DialogButtonBack onClick={props.onClose} />
      </DialogActions>
    </Dialog>
  )
}
