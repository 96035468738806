import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import ButtonDialogAccount from "./ButtonDialogAccount"
import ButtonLanguage from "./ButtonLanguage"

function Logo() {
  return (
    <Typography sx={{ fontFamily: 'Real-Text-ExtraBold', fontSize: '30px', lineHeight: 1, mb: 1.15 }}>lez</Typography>
  )
}

export default function Layout(props) {
  return (
    <>
      <AppBar position="sticky" color="tertiary_light" variant="outlined" elevation={0}>
        <Container>
          <Toolbar disableGutters>
            <Box sx={{ flex: 100, display: 'flex', justifyContent: 'flex-start', alignItems: 'center',  }}>
              <Button color='tertiary_dark' href="/" >
                <Logo  />
              </Button>
              <Chip label="beta" variant="outlined" size="small" color="warning" sx={{ ml: 0.5 }} />
            </Box>
            <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            </Box>
            <Box sx={{ flex: 100, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              <ButtonLanguage />
              <ButtonDialogAccount />
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Container>
        {props.children}
      </Container>
    </>
  )
}
