import { useEffect, useMemo } from 'react'
import { createHttpLink, ApolloClient, InMemoryCache, ApolloProvider, ApolloLink } from "@apollo/client"
import { setContext } from '@apollo/client/link/context'
import { RetryLink } from "@apollo/client/link/retry";
import { useTokens, useSsoState } from './sso'

export default function AppApolloProvider(props) {
  const { getAccessToken, refreshTokens } = useTokens()
  const { ssoState } = useSsoState()

  const client = useMemo(
    () => {
      const retryLink = new RetryLink({
        delay: {
          initial: 100,
          max: 5000,
        },
        attempts: {
          max: 2,
          retryIf: async (error) => {
            if (error && error.statusCode && error.statusCode === 401) {
              await refreshTokens()
              return true
            }
            return true
          },
        },
      });
      const authLink = setContext((_, { headers }) => {
        const accessToken = getAccessToken()
        return {
          headers: {
            ...headers,
            authorization: accessToken ? `Bearer ${accessToken}` : "",
          }
        }
      })
      const httpLink = createHttpLink({
        uri: 'https://stage.lezoom.fr/api/v2/graphql',
        credentials: 'include'
      })
      return new ApolloClient({
        link: ApolloLink.from([retryLink, authLink, httpLink]),
        cache: new InMemoryCache(),
      })
    },
    [getAccessToken, refreshTokens]
  );

  useEffect(() => {
    if (ssoState?.source !== 'init') {
      client.resetStore()
    }
  }, [ssoState, client])

  return (
    <ApolloProvider client={client}>
      {props.children}
    </ApolloProvider>
  )
}
