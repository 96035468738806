import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery, gql } from "@apollo/client"
import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse';
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import FaceIcon from '@mui/icons-material/Face';
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import LogoutIcon from '@mui/icons-material/Logout';
import DialogSignUp from "./DialogSignUp"
import { DialogButtonBack } from '../base/DialogButtons'
import { SignInIcon, SignUpIcon } from '../base/Icons'
import { useSignInOut } from '../../sso'

function DialogSignInUp(props) {
  const { t } = useTranslation()
  return (
    <Dialog keepMounted={props.keepMounted} open={props.open} onClose={props.onClose}>
      <DialogContent>
        <List>
          <ListItemButton onClick={props.onSignIn}>
            <ListItemIcon><SignInIcon /></ListItemIcon>
            <ListItemText primary={t('sign-in')} secondary={t('sign-in-help')} />
          </ListItemButton>
          <ListItemButton onClick={props.onSignUp}>
            <ListItemIcon><SignUpIcon /></ListItemIcon>
            <ListItemText primary={t('sign-up')} secondary={t('sign-up-help')} />
          </ListItemButton>
        </List>
      </DialogContent>
      <DialogActions>
        <DialogButtonBack onClick={props.onClose} />
      </DialogActions>
    </Dialog>
  )
}

function DialogUser(props) {
  const { t } = useTranslation()
  return (
    <Dialog keepMounted={props.keepMounted} open={props.open} onClose={props.onClose}>
      <DialogContent>
        <List>
          <ListItemButton onClick={props.onSignOut}>
            <ListItemIcon><LogoutIcon color='tertiary_dark' /></ListItemIcon>
            <ListItemText primary={t('sign-out')} secondary={t('sign-out-help')} />
          </ListItemButton>
        </List>
      </DialogContent>
      <DialogActions>
        <DialogButtonBack onClick={props.onClose} />
      </DialogActions>
    </Dialog>
  )
}

const GET_USER_DETAILS = gql`
query getUserDetails {
  getUserDetails {
    firstName
    email
  }
}`

export default function ButtonDialogAccount(props) {
  const { signin, signout } = useSignInOut()
  const [openDialogSignInUp, setOpenDialogSignInUp] = useState(false)
  const [openDialogSignUp, setOpenDialogSignUp] = useState(false)
  const [openDialogUser, setOpenDialogUser] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const { loading, error, data } = useQuery(GET_USER_DETAILS)

  const onSignIn = e => {
    setOpenDialogSignInUp(false)
    signin()
  }

  const onSignUp = e => {
    setOpenDialogSignInUp(false)
    setOpenDialogSignUp(true)
  }

  const onSignOut = e => {
    setOpenDialogUser(false)
    signout()
  }

  return (
    <>
      <Collapse orientation='horizontal' in={(data?.getUserDetails == null)}>
        <IconButton size='large' onClick={e => { setOpenDialogSignInUp(true) }}><FaceIcon color='tertiary_dark' /></IconButton>
      </Collapse>
      <Collapse orientation='horizontal' in={(data?.getUserDetails != null)}>
        <Button size='large' color='tertiary_dark' onClick={e => { setOpenDialogUser(true) }}>{data?.getUserDetails?.firstName}</Button>
      </Collapse>
      <DialogSignInUp
        keepMounted={false}
        open={openDialogSignInUp}
        onSignIn={onSignIn}
        onSignUp={onSignUp}
        onClose={e => setOpenDialogSignInUp(false)}
      />
      <DialogSignUp
        keepMounted={false}
        open={openDialogSignUp}
        onClose={e => { setOpenDialogSignUp(false) }}
      />
      <DialogUser
        keepMounted={(data?.getUserDetails != null)}
        open={openDialogUser}
        onSignOut={onSignOut}
        onClose={e => setOpenDialogUser(false)}
      />
    </>
  )
}
