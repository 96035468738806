import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid2';
import { enqueueSnackbar } from 'notistack'
import ButtonGeolocation from './ButtonGeolocation'
import ButtonDialogRadius from './ButtonDialogRadius'
import ButtonDialogSortBy from './ButtonDialogSortBy'
import ButtonDialogTimeInterval from './ButtonDialogTimeInterval'
import ButtonDialogTags from './ButtonDialogTags'
import PlaceAutocomplete from '../base/PlaceAutocomplete'
import { SortByEnum } from '../../defs/SortBy'
import TagStack from '../tag/TagStack'
import { TimeIntervalVsEventsDisplayEnum } from '../../defs/TimeIntervalVsEventsDisplay'
import { evqRecents } from '../../model/Recents'

const defaults = {
  place: null,
  radius: 20,
  timeIntervalID: null,
  timeIntervalVsEventsDisplay: TimeIntervalVsEventsDisplayEnum.ALL,
  tagsIn: [],
  tagsOut: [],
  sortBy: SortByEnum.DATE
}

export default function EvqPanel(props) {
  const { t } = useTranslation()
  const [defaultEvq, setDefaultEvq] = useState({...defaults})
  const [evq,        setEvq]        = useState({...defaults})

  useEffect(() => {
    if (props.evQ != null) {
      setEvq({...props.evQ})
    }
  }, [props.evQ])

//  const onReset = e => {
//    e.preventDefault()
//    setEvq({...defaultEvq})
//  }

//  const onRestore = e => {
//    e.preventDefault()
//    if (props.evQ != null) {
//      setEvq({...props.evQ})
//    }
//  }

  const onSubmit = e => {
    e.preventDefault()
    if (evq.place == null) {
      enqueueSnackbar(t("search-where-help"), { variant: 'info', preventDuplicate: true })
      return
    }
    const evq_ = {...evq}
    evqRecents.push(evq_)
    props.onSubmit(evq_)
  }

  const onGeolocate = place_ => {
    place_.groupName = 'Ma position'
//  setUserLocationOpts([place_])
//  props.onChange(place_)
    setEvq({...evq, place: place_})
  }

  return (
    <Grid container spacing={1} sx={{ mt: 1 }}>
      <Grid size={12}>
        <PlaceAutocomplete
          value={evq.place}
          onChange={value => setEvq({...evq, place: value})}
        />
      </Grid>
      <Grid size={6} sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }} >
        <ButtonDialogRadius
          value={evq.radius}
          onChange={value => setEvq({...evq, radius: value})}
        />
        <ButtonGeolocation onChange={onGeolocate} />
      </Grid>
      <Grid size={6}>
        <Button variant="contained" size="large" color='primary' onClick={onSubmit}>
          {t('button-search-text')}
        </Button>
      </Grid>
      <Grid size={6} sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
        <ButtonDialogTimeInterval
          timeIntervalID={evq.timeIntervalID}
          timeIntervalVsEventsDisplay={evq.timeIntervalVsEventsDisplay}
          onTimeIntervalDefaultChange={value => setDefaultEvq({...defaultEvq, timeIntervalID: value})}
          onTimeIntervalChange={value => setEvq({...evq, timeIntervalID: value})}
          onChange={(id_,display_) => setEvq({...evq, timeIntervalID: id_, timeIntervalVsEventsDisplay: display_})}
        />
      </Grid>
      <Grid size={6}>
      </Grid>
      <Grid size={6} sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
        <ButtonDialogSortBy
          value={evq.sortBy}
          onChange={value => setEvq({...evq, sortBy: value})}
        />
      </Grid>
      <Grid size={6}>
      </Grid>
      <Grid size={6} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 1 }} >
        { ((evq.tagsIn.length !== 0) || (evq.tagsOut.length !== 0)) &&
        <Box sx= {{ display: 'flex', justifyContent: 'flex-start', gap: 1 }}>
          <TagStack tags={evq.tagsIn} />
          <TagStack tags={evq.tagsOut} blacklisted={true} />
        </Box>
        }
        <ButtonDialogTags
          tagsIn={evq.tagsIn}
          tagsOut={evq.tagsOut}
          onTagsInChange={tags => setEvq({...evq, tagsIn: tags})}
          onTagsOutChange={tags => setEvq({...evq, tagsOut: tags})}
        />
      </Grid>
    </Grid>
  )
}
