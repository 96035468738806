import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'

export function DialogButtonBack(props) {
  const { t } = useTranslation()
  return (
    <Button {...props} size='large' color='tertiary_dark' disableElevation >{t('button-back')}</Button>
  )
}

export function DialogButtonOk(props) {
  const { t } = useTranslation()
  return (
    <Button {...props} size='large' disableElevation variant='contained' >{t('button-ok')}</Button>
  )
}

export function DialogButtonValidate(props) {
  const { t } = useTranslation()
  return (
    <Button {...props} size='large' disableElevation variant='contained' >{t('button-validate')}</Button>
  )
}
