import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { enqueueSnackbar } from 'notistack'
import Fab from '@mui/material/Fab'
import PublicIcon from '@mui/icons-material/Public';
import { geolocate } from '../../utils/geolocation'
import BoxResizable from "../map/BoxResizable"
import EventMap from "../map/EventMap"

export default function EvMap(props) {
  const [showMap, setOpenMap] = useState(false)
  const [userLatLon, setUserLatLon] = useState(null)
  const { t } = useTranslation()
  const onUserLatLonRequest = () => {
    geolocate(
      latLon => {
        setUserLatLon(latLon)
      },
      error => {
        enqueueSnackbar(error, { variant: 'error' })
      }
    )
  }
  return (
    <>
      { (props.evR && (props.evR.events.length !== 0)) &&
      <>
        <Fab variant="extended" size="large" color="secondary"
          sx={{position: 'fixed', bottom: '20px', left: '20px' }}
          onClick={e => setOpenMap(!showMap)}
        >
          <PublicIcon fontSize="small" sx={{ mr: 1 }} />{t('button-map')}
        </Fab>
        <BoxResizable
          open={showMap}
          onClose={e => setOpenMap(false)}
          minHeight={100}
        >
          <EventMap
            events={props.evR.events}
            onUserLatLonRequest={onUserLatLonRequest}
            userLatLon={userLatLon}
          />
        </BoxResizable>
      </>
      }
    </>
  )
}
