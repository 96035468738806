import { useEffect, useState, useCallback } from 'react'
import { useLazyQuery, gql } from "@apollo/client"
import { enqueueSnackbar } from 'notistack'
import { evTransform } from "../../model/Ev"
import { decodeEvQFromUrlParams } from '../../model/evq/url'
import DialogTagChoice from '../tag/DialogTagChoice'
import EvGrid from "../ev/EvGrid"
import Layout from "../layout/Layout"
import { EvPopup } from '../ev/EvPopup'
import EvMap from '../map/EvMap'

const LIST_EVENTS_CIRC = gql`
query listEventsCirc(
  $lat: Float!
  $lon: Float!
  $radius: Int
  $startDate: String
  $endDate: String
  $dtWiMo: Int
  $timeIntervalID: Int
  $tagsIn: [String]
  $tagsOut: [String]
  $sortBy: Int
  $pageLen: Int
  $pageIndex: Int
) {
  listEventsCirc(
    lat: $lat
    lon: $lon
    radius: $radius
    timeIntervalID: $timeIntervalID
    startDate: $startDate
    endDate: $endDate
    dtWiMo: $dtWiMo
    tagsIn: $tagsIn
    tagsOut: $tagsOut
    sortBy: $sortBy
    pageLen: $pageLen
    pageIndex: $pageIndex
  ) {
    totalPages
    totalElements
    offset
    size
    currentPage
    events {
      id
      name
      location
      starttime
      endtime
      coordinates
      tags {
        id
        name
        bgColor
        color
      }
    }
  }
}`

export default function ResultsPage(props) {
  // eslint-disable-next-line no-unused-vars
  const [listEventsCirc, { called, loading, data }] = useLazyQuery(LIST_EVENTS_CIRC);
  const [currentEvQ,    setCurrentEvQ]    = useState(null)
  const [currentEvR,    setCurrentEvR]    = useState(null)
  const [searchOnGoing, setSearchOnGoing] = useState(false)
  const [tagSelected,   setTagSelected]   = useState(null)
  const [evPopupSelect, setEvPopupSelect] = useState(null)
  const [openEvPopup,   setOpenEvPopup]   = useState(false)
  const [openDialogTag, setOpenDialogTag] = useState(false)

  const onSubmit = useCallback((evQ_) => {
    console.log(evQ_)
    setCurrentEvQ(evQ_)
    setSearchOnGoing(true)
    listEventsCirc({
      variables: {
        lat: evQ_.place.lat,
        lon: evQ_.place.lon,
        radius: evQ_.radius,
        timeIntervalID: evQ_.timeIntervalID,
        startDate: evQ_.startDate,
        endDate: evQ_.endDate,
        dtWiMo: evQ_.timeIntervalVsEventsDisplay,
        tagsIn: (evQ_.tagsIn.length === 0) ? undefined : evQ_.tagsIn.map(tag => tag.id),
        tagsOut: (evQ_.tagsOut.length === 0) ? undefined : evQ_.tagsOut.map(tag => tag.id),
        sortBy: evQ_.sortBy,
        pageLen: evQ_.pageLen,
        pageIndex: evQ_.pageIndex
      },
      onCompleted: data => {
        console.log(data)
        let arr_ = []
        data.listEventsCirc.events.forEach((data) => {
          arr_.push(evTransform(data))
        })
        const evR_ = {
          pageNum: data.listEventsCirc.currentPage + 1, // todo: rename currentPage into pageIndex
          pageCount: data.listEventsCirc.totalPages,
          events: arr_
        }
        setCurrentEvR(evR_)
        setSearchOnGoing(false)
      },
      onError: err => {
        setSearchOnGoing(false)
        enqueueSnackbar("La recherche n'a pu aboutir.", { variant: 'error' })
        enqueueSnackbar(`${err}`, { variant: 'error' })
      }
    })
  }, [listEventsCirc])

  useEffect(() => {
    const url = new URL(document.location)
    const evQ_ = decodeEvQFromUrlParams(url.searchParams)
    if (evQ_ != null) {
      onSubmit(evQ_)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onPageChange = pageNum => {
    if (currentEvQ == null) { return }
    const evQ_ = {...currentEvQ}
    evQ_.pageIndex = pageNum-1
    onSubmit(evQ_)
  }

  const onTagsChange = (tagsIn, tagsOut) => {
    setOpenDialogTag(false)
    const evQ_ = {...currentEvQ}
    evQ_.tagsIn = tagsIn
    evQ_.tagsOut = tagsOut
    evQ_.pageIndex = 0
    onSubmit(evQ_)
  }

  return (
    <Layout>
      <EvGrid
        loading={searchOnGoing}
        pageNum={currentEvR && currentEvR.pageNum}
        pageCount={currentEvR && currentEvR.pageCount}
        events={currentEvR && currentEvR.events}
        onPageChange={pageNum => onPageChange(pageNum) }
        onEvClick={ev => { setEvPopupSelect(ev); setOpenEvPopup(true) }}
        onTagClick={tag => { setTagSelected(tag); setOpenDialogTag(true) }}
        refPlace={currentEvQ && currentEvQ.place}
      />
      <EvMap evR={currentEvR} />
      <DialogTagChoice
        open={openDialogTag}
        onClose={e => setOpenDialogTag(false) }
        tag={tagSelected}
        tagsIn={currentEvQ && currentEvQ.tagsIn }
        tagsOut={currentEvQ && currentEvQ.tagsOut }
        onTagsChange={onTagsChange}
      />
      <EvPopup
        open={openEvPopup}
        onClose={e => setOpenEvPopup(false) }
        ev={evPopupSelect}
      />
    </Layout>
  )
}
