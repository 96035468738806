import { useEffect, useState, useCallback } from 'react'
import { useNavigate } from "react-router";
import { decodeEvQFromUrlParams, encodeEvQIntoUrlParams } from '../../model/evq/url'
import Layout from "../layout/Layout"
import EvqPanel from '../evq/EvqPanel'
import { EvRecents } from '../ev/EvRecents'
import { EvqRecents } from '../evq/EvqRecents'

export default function LandingPage(props) {
  const navigate = useNavigate();
  const [currentEvQ, setCurrentEvQ] = useState(null)

  useEffect(() => {
    const url = new URL(document.location)
    const evQ_ = decodeEvQFromUrlParams(url.searchParams)
    setCurrentEvQ(evQ_)
  }, [])

  const onSubmit = useCallback((evQ_) => {
    navigate("/events?" + encodeEvQIntoUrlParams(evQ_).toString())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Layout>
      <EvqPanel
        evQ={currentEvQ}
        onSubmit={onSubmit}
      />
      <EvRecents />
      <EvqRecents onEvq={onSubmit} />
    </Layout>
  )
}
