import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { DialogButtonBack } from '../base/DialogButtons'
import { FlagFrIcon, FlagDeIcon, FlagUkIcon } from '../base/Icons'

export default function ButtonLanguage(props) {
  const { i18n } = useTranslation()
  const [showDialog, setShowDialog] = useState(false)
  const onDialogOpen = e => {
    setShowDialog(true)
  }
  const onDialogClose = e => {
    setShowDialog(false)
  }
  const onChange = language => {
    i18n.changeLanguage(language)
    setShowDialog(false)
  }
  return (
    <>
      <IconButton size='large' onClick={onDialogOpen}>
        { (i18n.resolvedLanguage === "fr") && <FlagFrIcon /> }
        { (i18n.resolvedLanguage === "de") && <FlagDeIcon /> }
        { (i18n.resolvedLanguage === "en") && <FlagUkIcon /> }
      </IconButton>
      <Dialog open={showDialog} onClose={onDialogClose}>
        <DialogContent>
          <List>
            <ListItemButton onClick={() => { onChange("fr") }}>
              <ListItemIcon><FlagFrIcon fontSize="large" /></ListItemIcon>
              <ListItemText>Français</ListItemText>
            </ListItemButton>
            <ListItemButton onClick={() => { onChange("de") }}>
              <ListItemIcon><FlagDeIcon fontSize="large" /></ListItemIcon>
              <ListItemText>Deutsch</ListItemText>
            </ListItemButton>
            <ListItemButton onClick={() => { onChange("en") }}>
              <ListItemIcon><FlagUkIcon fontSize="large" /></ListItemIcon>
              <ListItemText>English</ListItemText>
            </ListItemButton>
          </List>
        </DialogContent>
        <DialogActions>
          <DialogButtonBack onClick={onDialogClose} />
        </DialogActions>
      </Dialog>
    </>
  )
}
